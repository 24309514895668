import React, { useState, useEffect, Suspense, useContext } from 'react';
import './IdeallineApp.css';
import './Loading.css';
import { useRouteMatch, useHistory} from "react-router-dom";
const LineTool = React.lazy(() => import('./LineTool'));
import List from './List'
import usePrevious from './usePrevious'

import { FirebaseContext } from './Firebase'
import 'firebase/auth'
import 'firebase/firestore'
import 'firebase/analytics'
import 'firebase/storage'

const App = (props) => {
  const [isTool, setIsTool] = useState(false);
  const [lineId, setLineId] = useState(null);
  const match = useRouteMatch("*/r/:lineId");
  const [ignoreMatch, setIgnoreMatch] = useState(false);
  const history = useHistory();
  const prevMatch = usePrevious(match)
  const [editMode, setEditMode] = useState(false);

  // History stacks for undo/redo
  const [undoStack, setUndoStack] = useState([]);
  const [redoStack, setRedoStack] = useState([]);

  // Track states
  const trackDefaults = {
    points: [],
    lat: props.lat,
    lng: props.lng,
    zoom: props.zoom || 19,
    visibility: "public",
    vehicle: ""
  }
  const [track, setTrack] = useState(trackDefaults)
  const [zoom, setZoom] = useState(track.zoom)
  const [errorMessage, setErrorMessage] = useState(null)
  const [center, setCenter] = useState({lat: track.lat, lng: track.lng});
  const [isCloseDetected, setIsCloseDetected] = useState(false);

  
  // Firebase 
  const auth = useContext(FirebaseContext)
  const firebase = auth.app
  const linesDb = firebase.firestore().collection(`lines`)
  const user = auth.user
  const {requireAuth} = auth
  const signInAnonymouslyAndGetUser = auth.signInAnonymouslyAndGetUser
  
  const [socialImage, setSocialImage] = useState(null)
  const [socialImageUrl, setSocialImageUrl] = useState(null)
  const [doUploadImage, setDoUploadImage] = useState(null)
  const storageRef = firebase.storage().ref()
  const imagesRef = storageRef.child('lines/images')

  useEffect(() => {
    const hash = history.location.hash
    if (!hash.startsWith('#/')) return
    const matchedLineId = hash.replace("#/", "")
    pushPath(matchedLineId, false)
   }, [history])

 useEffect(() => {
   console.log("new line id", match)
    if(match && match.params.lineId
      && prevMatch && prevMatch.params.lineId
      && prevMatch.params.lineId == match.params.lineId) {
        return
    }

    if(ignoreMatch) {
      setIgnoreMatch(false)
      return
    }
    
    if(match && match.params.lineId) {
      setLineId(match.params.lineId)
      setEditMode(false)
    } 
    else {
      setLineId(null)
      setIsTool(false)
      setErrorMessage(null)
    }
  }, [match])

  // Create doc when line Id is not provided
  useEffect(() => {
    if(!lineId) return

    const unsubscribe = load()
    logRacingLineView()
    loadSocialImageURL(lineId)
    if(!match || match.params.lineId != lineId) pushPath(lineId)

    // Stop listening to changes
    return () => unsubscribe()
  }, [lineId, user])


  const pushPath = (lineId, setIgnore = true) => {
    let baseUrl = match ? match.params[0] : history.location.pathname
    if(baseUrl == "/") baseUrl = ""
    const path = baseUrl + "/r/" + lineId
    if(setIgnore) setIgnoreMatch(true)
    history.push(path)
  }

  const onDrawNewLine = async user => {
    setTrack(trackDefaults)
    setIsCloseDetected(false)
    setLineId(await createDoc(trackDefaults, user))
    setEditMode(true)
  }


  // On every change of the points
  useEffect(() => {
    if(track.points.length && editMode){ 
        firebase.analytics().logEvent('draw_point');
        // Autosave on every fifth point
        if(track.points.length % 5 === 0) save()
    }
  }, [track.points])

  const redoLast = () => {
    if (track.points.length === 0) return;
    const pointsCopy = track.points.slice();
    // Save current state to undo stack
    setUndoStack(prev => [...prev, track.points]);
    // Clear redo stack
    setRedoStack([]);
    // Remove last point
    pointsCopy.splice(-1, 1);
    setTrack(prev => ({...prev, points: pointsCopy}));
    firebase.analytics().logEvent('redo');
  };

  const duplicate = async user => {
    const trackWithoutLikes = {...track}
    trackWithoutLikes.likes = []
    setLineId(await createDoc(trackWithoutLikes, user))
    setTrack(trackWithoutLikes)
    setEditMode(true)
    firebase.analytics().logEvent('duplicate');
  }

  const done = async () => {
    setDoUploadImage(true)
    setEditMode(false)
    await save()
    firebase.analytics().logEvent('done', {lineId});
  }

  const onSetLineId = lineId => {
    setLineId(lineId)
  }

  const onSetLine = line => {
    setLineId(line.id)
  }

  const onDelete = async () => {
    if(!window.confirm('Are you sure you wish to delete this racing line?')) return

    try {
      const doc = linesDb.doc(lineId)
      await doc.delete()

      setLineId(null)
      history.push(track.pathname)
      setEditMode(false)
      firebase.analytics().logEvent('delete');
      console.log("Racing line deleted: ", lineId)
    }
    catch(error) {
        console.error("Error deleting document: ", error);
    }
  }


  const createDoc = async (trackValues, currentUser) => {
    const { name, placeId, url } = props
    const t = trackValues
    try {
      const doc = linesDb.doc()
      await doc.set({
          name,
          placeId,
          created: firebase.firestore.FieldValue.serverTimestamp(),
          location: new firebase.firestore.GeoPoint(+t.lat, +t.lng),
          url,
          zoom: t.zoom,
          points: t.points,
          user: currentUser.uid,
          likes: []
      }, { merge: true })
      
      console.log("Document created with id: ", doc.id)

      return doc.id
    }
    catch(error) {
        console.error("Error creating document: ", error);
    }
  }

  const logRacingLineView = () => {
    firebase.analytics().logEvent('select_content', {
      content_type: 'racing line',
      content_id: lineId,
      items: [{ name: props.name }]
    });
  }

  const load = () => {
    try {
        return linesDb.doc(lineId).onSnapshot(async doc => {
          if (!doc.exists) {
            setIsTool(false)
            setLineId(null)
            setEditMode(false)
            setErrorMessage("Racing line could not be found.")
            return
          }
          const data = doc.data()
          const points = data.points
          if(!points) {
            setTrackStates({points: []})
            return
          }
        
          const location = data.location
          setTrack({
              ...data,
              lat: location.latitude,
              lng: location.longitude,
              zoom: data.zoom || track.zoom,
              pathname: data.url,
              visibility: data.visibility || track.visibility,
              isOwner: user && user.uid == data.user,
              vehicle: data.vehicle || ""
          })
          setIsTool(true)
          console.log("Points loaded from id: ", doc.id);
          const logEvent = props.embedLayout ? 'load_embed_line' : 'load_line'
          firebase.analytics().logEvent(logEvent);
        })
    }
    catch(error) {
        console.error("Error loading document: ", error);
    }
  }

  const uploadSocialImage = async socialImage => {
    const fileName = `${lineId}.png`;
    const imageRef = imagesRef.child(fileName);

    const snapshot = await imageRef.putString(socialImage, 'data_url')
    const downloadURL = await snapshot.ref.getDownloadURL()
    setSocialImageUrl(downloadURL)
    console.log("image uploaded")
  }

  const loadSocialImageURL = async lineId => {
    try {
      const fileName = `${lineId}.png`;
      const imageRef = imagesRef.child(fileName);

      const url = await imageRef.getDownloadURL()
      setSocialImageUrl(url)
    }
    catch(error) {
      if(error.code == 'storage/object-not-found') {
        setSocialImageUrl(null)
        return
      }
      console.error("Error loading image: ", error);
    }
  }

  const save = async () => {
    try {
      const doc = linesDb.doc(lineId)
      await doc.set({
          points: track.points, 
          zoom,
          location: new firebase.firestore.GeoPoint(+center.lat, +center.lng),
          visibility: track.visibility,
          isCloseDetected,
          vehicle: track.vehicle
      }, { merge: true });

      console.log("Points written to id: ", doc.id);
    }
    catch(error) {
        console.error("Error updating document: ", error);
    }
    
  }

  const setTrackStates = states => {
    setTrack(prevState =>  ({ 
      ...prevState,
      ...states
    }))
  }

  const setVisibility = visibility => {
    setTrackStates({visibility})
  }

  const setPoints = (newPoints) => {
    // Save current state to undo stack before updating
    setUndoStack(prev => [...prev, track.points]);
    // Clear redo stack since we're creating a new branch of history
    setRedoStack([]);
    // Update track with new points
    setTrack(prev => ({...prev, points: newPoints}));
  };

  const setVehicle = vehicle => {
    setTrackStates({vehicle})
  }

  const setImage = image => {
    setSocialImage(image)
  }

  useEffect(() => {
    if(!socialImage || !doUploadImage) return
    
    uploadSocialImage(socialImage)
    setDoUploadImage(false)
  },[socialImage])
  
  const centerQuery = props.lat + "," + props.lng
  const query = props.placeId && props.placeId != "nan" ? "place_id:" + props.placeId + "&center=" + centerQuery : centerQuery;
  const src = `https://www.google.com/maps/embed/v1/place?q=${query}&maptype=satellite&zoom=${props.zoom}&key=${process.env.GATSBY_GMAPS_EMPED_API_KEY}`

  const renderLoader = () => <div className="lds-dual-ring"></div>

  const undo = () => {
    if (undoStack.length === 0) return;
    
    // Get the last state from undo stack
    const prevPoints = undoStack[undoStack.length - 1];
    // Add current state to redo stack
    setRedoStack(prev => [...prev, track.points]);
    // Restore previous state
    setTrack(prev => ({...prev, points: prevPoints}));
    // Remove the used state from undo stack
    setUndoStack(prev => prev.slice(0, -1));
    
    firebase.analytics().logEvent('undo');
  };

  const redo = () => {
    if (redoStack.length === 0) return;
    
    // Get the last state from redo stack
    const nextPoints = redoStack[redoStack.length - 1];
    // Add current state to undo stack
    setUndoStack(prev => [...prev, track.points]);
    // Restore next state
    setTrack(prev => ({...prev, points: nextPoints}));
    // Remove the used state from redo stack
    setRedoStack(prev => prev.slice(0, -1));
    
    firebase.analytics().logEvent('redo');
  };

  return (
    <>
      {!isTool && !props.embedLayout ?  
        <>
          <div className="row-full">
            <iframe
                id="embed-map"
                width="100%"
                height="400"
                frameBorder="0"
                src = {src}
                />
          </div>
          {errorMessage && <span>{errorMessage}</span>}
        </>
        :
        <Suspense fallback={renderLoader()}>
          <LineTool 
              lineId={lineId} 
              editMode={editMode}
              setLineId={onSetLineId} 
              track={track}
              setPoints={setPoints}
              setCenter={setCenter}
              setZoom={setZoom}
              setEditMode={setEditMode}
              setIsCloseDetected={setIsCloseDetected}
              isCloseDetected={isCloseDetected}
              duplicate={() => requireAuth(duplicate)}
              done={done}
              redoLast={redoLast}
              delete={onDelete}
              setVisibility={setVisibility}
              setVehicle={setVehicle}
              setImage={setImage}
              socialImageUrl={socialImageUrl}
              exit={() => {setLineId(null); setIsTool(false); setEditMode(false)}}
              undo={undo}
              canUndo={undoStack.length > 0}
              redo={redo}
              canRedo={redoStack.length > 0}
              {...props} />

        </Suspense>   

      }
      {!props.embedLayout && 
          <List
              setLine={onSetLine} 
              onNewLine={e => {
                e.preventDefault();
                requireAuth(onDrawNewLine)
              }}
              alwaysShowNewLine={true}
              oneRacingLinePerTrack={false}
              filterTrack={props.name}
              showLike={true}
              limit={100}/>}
          
      
    </>
  )
};

export default App;
